import React from "react";
import { useFormContext } from "react-hook-form";
import { Radio } from "../Radio";
import { Text } from "../Text";
import { Box } from "../Box";
import styled from "styled-components";

export const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  checked?: boolean;
  label?: React.ReactNode;
};

export const FormRadio: React.FunctionComponent<Props> = ({
  name,
  checked,
  label,
  ...rest
}) => {
  const { register } = useFormContext();
  const wrapper = label ? Label : Box;

  return React.createElement(
    wrapper,
    {},
    <>
      <Radio {...rest} {...register(name)} />
      {label && <Text>{label}</Text>}
    </>
  );
};
