import styled from "styled-components";
import { Input as UnstyledInput } from "../Input";
import { Button } from "@questdb/react-components";
import { Clipboard } from "@styled-icons/bootstrap/Clipboard";
import { toast } from "../Toast";
import React from "react";

type CopyableProps = {
  value: string | null;
};

const Root = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
`;

const Input = styled(UnstyledInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: ${({ theme }) => theme.color.cyan};
`;

const CopyButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 1.2rem;
  padding: 0 2rem;
  cursor: pointer;
`;

// @TODO: would be nice to have a universal copy handler, perhaps as a hook
export const makeHandleCopy = (value: string) => (event: React.MouseEvent) => {
  event.preventDefault();
  navigator.clipboard.writeText(value);
  toast.info("Copied to clipboard", {
    hideProgressBar: true,
    autoClose: 2000,
  });
};

export const Copyable = ({ value }: CopyableProps) => {
  if (!value) return null;

  return (
    <Root>
      <Input value={value} variant="transparent" disabled autoComplete="off" />
      <CopyButton
        skin="secondary"
        onClick={makeHandleCopy(value)}
        title="Copy Build Version"
        prefixIcon={<Clipboard size="14" />}
      >
        Copy
      </CopyButton>
    </Root>
  );
};
