import { useCallback, useState } from "react";
import styled from "styled-components";
import { Input as UnstyledInput } from "../Input";
import { Button } from "@questdb/react-components";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import { EyeSlash } from "@styled-icons/bootstrap/EyeSlash";
import { Clipboard } from "@styled-icons/bootstrap/Clipboard";
import { makeHandleCopy } from "../Copyable";

type PasswordProps = {
  value: string | null;
  showByDefault?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Input = styled(UnstyledInput)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  color: ${({ theme }) => theme.color.cyan};
`;

const ToggleButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const CopyButton = styled(Button)`
  border-radius: 0;
`;

export const Password = ({ value, showByDefault }: PasswordProps) => {
  const [show, setShow] = useState(showByDefault);

  const handleToggle = useCallback(() => {
    setShow(!show);
  }, [show, setShow]);

  if (!value) return null;

  return (
    <Wrapper>
      <Input
        value={value}
        variant="transparent"
        type={show ? "text" : "password"}
        disabled
        autoComplete="off"
      />
      <CopyButton
        skin="secondary"
        onClick={makeHandleCopy(value)}
        title="Copy to clipboard"
      >
        <Clipboard size={16} />
      </CopyButton>
      <ToggleButton
        skin="secondary"
        onClick={handleToggle}
        title="Toggle password visibility"
      >
        {show ? <EyeFill size={18} /> : <EyeSlash size={18} />}
      </ToggleButton>
    </Wrapper>
  );
};
