import React, { ReactNode } from "react";
import Head from "next/head";
import styled from "styled-components";
import { NavBar } from "../../modules/navbar";
import { TopBar } from "../../components";
import { Session } from "../../server/auth/api";
import { useUsersMe } from "../../modules/auth/api";
import * as Sentry from "@sentry/nextjs";

type Props = {
  children: ReactNode;
  TopBarNavigator?: ReactNode;
  title?: string;
  sessionData?: Session;
  wrapper?: React.ElementType;
};

const siteTitle = "QuestDB Cloud";

const Content = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  width: calc(100% - ${({ theme }) => theme.navbar.width});
  margin-left: ${({ theme }) => theme.navbar.width};
  margin-top: ${({ theme }) => theme.topbar.height};
`;

export const Layout = ({
  children,
  title,
  wrapper = Content,
  TopBarNavigator,
  sessionData,
}: Props) => {
  const userQuery = useUsersMe(sessionData as Session, {
    enabled: Boolean(sessionData),
    onSuccess: (user) => {
      Sentry.setUser(user);
    },
  });

  const metaTitle = title != null ? `${title} | ${siteTitle}` : siteTitle;
  const Wrapper = wrapper;

  return (
    <>
      <Head>
        <title>{metaTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="initial-scale=1.0, minimum-scale=1.0, width=device-width"
        />
      </Head>
      {sessionData && <NavBar session={sessionData} />}
      {sessionData && (
        <TopBar
          TopBarNavigator={TopBarNavigator}
          session={sessionData}
          user={userQuery.data}
        />
      )}
      <Wrapper>{children}</Wrapper>
    </>
  );
};
