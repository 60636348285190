import React from "react";
import type { ReactNode } from "react";
import { Text } from "../../components";
import { Heading } from "@questdb/react-components";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem;
  min-height: 5rem;
  background: #303241;
  border-radius: ${({ theme }) =>
    [theme.borderRadius, theme.borderRadius, 0, 0].join(" ")};
  border-bottom: 1px solid #3b3e50;

  &:only-child {
    border-radius: ${({ theme }) => theme.borderRadius};
    border-bottom: none;
  }
`;

export const AfterTitle = styled.div`
  margin-left: auto;
`;

const Titles = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const Footer = styled.div`
  flex: 1 0 100%;
`;

type Props = {
  title?: ReactNode;
  subtitle?: ReactNode;
  beforeTitle?: ReactNode;
  afterTitle?: ReactNode;
  footer?: ReactNode;
  prefixIcon?: ReactNode;
  className?: string;
};

const has = (...value: unknown[]) =>
  value.reduce((acc, curr) => acc || curr, false);

export const CardHeader = ({
  title,
  subtitle,
  beforeTitle,
  afterTitle,
  footer,
  className,
}: Props) => (
  <Root className={className}>
    {has(beforeTitle) && <div>{beforeTitle}</div>}

    {has(title, subtitle) && (
      <Titles>
        {has(title) && <Heading level={5}>{title}</Heading>}
        {has(subtitle) && <Text color="white">{subtitle}</Text>}
      </Titles>
    )}

    {has(afterTitle) && <AfterTitle>{afterTitle}</AfterTitle>}

    {has(footer) && <Footer>{footer}</Footer>}
  </Root>
);
