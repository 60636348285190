import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import api from "../api";
import { QueryClient } from "@tanstack/react-query";
import type { User } from "../user";
import type { Session } from "../../server/auth/api";

export const getUsersMe = async (session: Session) =>
  api.get<User>({ endpoint: "users/me", session });

export const useUsersMe = (
  session: Session,
  queryOptions?: UseQueryOptions<User, unknown, User, ["users/me"]>
) => useQuery(["users/me"], () => getUsersMe(session), queryOptions);

export const prefetchUsersMe = async (
  queryClient: QueryClient,
  session: Session
) => queryClient.prefetchQuery(["users/me"], () => getUsersMe(session));
