export enum UserRole {
  ADMIN = "admin",
  USER = "USER",
}

export enum BillingType {
  individual = "individual",
  business = "business",
}

export type Organisation = {
  id: string;
  billing_type: BillingType;
  billing_email: string;
  billing_address_line_1: string;
  billing_address_line_2: string;
  billing_address_country: string;
  billing_address_city: string;
  billing_address_postal_code: string;
  billing_address_state: string;
  name: string;
  role: UserRole;
};

export type OrganisationOptions = {
  id: string;
  organisation_id: string;
  available_questdb_versions: string[];
  available_instance_types_aws: string[];
  available_volume_sizes_aws: number[];
  can_launch_instances: boolean;
  max_allowed_instance_count: number;
  trial_start: null | string;
  trial_end: null | string;
};
