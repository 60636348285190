import Image from "next/image";

type Props = {
  width?: number;
  height?: number;
};

export const CloudLogo = ({ width = 200, height = 50 }: Props) => (
  <div style={{ display: "flex", minWidth: width, minHeight: height }}>
    <Image
      width={width}
      height={height}
      src="/questdb-cloud.svg"
      alt="QuestDB Cloud Logo"
    />
  </div>
);
