export const groupBy = <T extends Record<string, any>>(
  array: T[],
  key: string
) => {
  return array.reduce((result: Record<string, T[]>, currentValue: T) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};
