import React from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { ChevronLeft } from "@styled-icons/bootstrap/ChevronLeft";
import { Button } from "@questdb/react-components";

type Props = {
  title: React.ReactNode;
  afterTitle?: React.ReactNode;
  beforeTitle?: React.ReactNode;
  actions?: React.ReactNode;
  withBackButton?: boolean;
  backButtonPath?: string;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  margin-bottom: 4rem;
`;

const BeforeTitle = styled.div`
  margin-right: 1rem;
`;

const Title = styled.h1`
  margin: 0;
  max-width: 700px;
`;

const AfterTitle = styled.div`
  margin-left: 1rem;
`;

const Actions = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const BackButton = styled(Button)`
  margin-right: 1rem;
  flex-shrink: 0;
`;

export const PageHeading: React.FunctionComponent<Props> = ({
  title,
  afterTitle,
  actions,
  beforeTitle,
  withBackButton,
  backButtonPath,
}) => {
  const router = useRouter();

  return (
    <Root>
      {withBackButton && (
        <BackButton
          skin="transparent"
          rounded
          dataHook="page-back-button"
          onClick={() => {
            if (backButtonPath) {
              router.push(backButtonPath);
            } else {
              router.back();
            }
          }}
        >
          <ChevronLeft size={16} />
        </BackButton>
      )}

      {beforeTitle && <BeforeTitle>{beforeTitle}</BeforeTitle>}

      <Title>{title}</Title>

      {afterTitle && <AfterTitle>{afterTitle}</AfterTitle>}
      {actions && <Actions>{actions}</Actions>}
    </Root>
  );
};
