import type { Color, FontSize } from "./types";

export const color: { [key in Color]: string } = {
  black: "#191a21",
  black70: "rgba(25, 26, 33, 0.7)",
  black40: "rgba(25, 26, 33, 0.4)",
  black20: "rgba(25, 26, 33, 0.2)",
  gray1: "#585858",
  gray2: "#bbbbbb",
  backgroundLighter: "#282a36",
  background: "#21222c",
  foreground: "#f8f8f2",
  selection: "#44475a",
  selectionDarker: "#333544",
  comment: "#6272a4",
  red: "#ff5555",
  orange: "#ffb86c",
  yellow: "#f1fa8c",
  green: "#50fa7b",
  purple: "#bd93f9",
  cyan: "#8be9fd",
  pink: "#d14671",
  pink50: "rgba(209, 70, 113, 0.25)",
  pinkDarker: "#be2f5b",
  transparent: "transparent",
  white: "#fafafa",
  inherit: "inherit",
  tooltipBackground: "#393a42",
  graphLegend: "#6e7078",
};

export const fontSize: { [key in FontSize]: string } = {
  ms: "1rem",
  xs: "1.2rem",
  sm: "1.3rem",
  md: "1.4rem",
  lg: "1.5rem",
  xl: "1.7rem",
  hg: "3rem",
};

export const theme = {
  baseFontSize: "10px",
  color,
  font: '"Open Sans", -apple-system, BlinkMacSystemFont, Helvetica, Roboto, sans-serif',
  fontEmoji:
    '"apple color emoji", "segoe ui emoji", "android emoji", "emojisymbols", "emojione mozilla", "twemoji mozilla", "segoe ui symbol", "noto color emoji"',
  fontMonospace:
    'SFMono-Regular, Menlo, Monaco, Consolas,"Liberation Mono", "Courier New", monospace',
  fontSize,
  navbar: {
    width: "12rem",
  },
  topbar: {
    height: "5rem",
  },
  borderRadius: "0.8rem",
};
