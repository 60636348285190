import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { Session } from "../../server/auth/api";
import api from "../api";
import { Activity, ShowOptions } from "./types";

export const getUserActivities = async (session: Session, show?: ShowOptions) =>
  api.get<Activity[]>({
    endpoint: "user-activities",
    session,
    params: { show },
  });

export const useUserActivities = (
  session: Session,
  show?: ShowOptions,
  queryOptions?: {
    enabled?: boolean;
  }
) =>
  useQuery(
    ["user-activities"],
    () => getUserActivities(session, show),
    queryOptions
  );

export const useUnreadUserActivities = (
  session: Session,
  queryOptions?: {
    refetchInterval: number;
    enabled: boolean;
  }
) =>
  useQuery(
    ["unread-user-activities"],
    () => getUserActivities(session, "unread"),
    queryOptions
  );

// Note: The following hook pair (query+prefetch) will use a different request in due course.
// For now, it's the same for both user and org, but it will be split eventually into user and organization level.
export const useOrgActivities = (
  session: Session,
  show?: ShowOptions,
  queryOptions?: {
    initialData?: Activity[];
    refetchInterval: number;
  }
) =>
  useQuery(
    ["org-activities"],
    () => getUserActivities(session, show),
    queryOptions
  );

export const prefetchOrgActivities = async (
  queryClient: QueryClient,
  session: Session
) =>
  queryClient.prefetchQuery(["org-activities"], () =>
    getUserActivities(session)
  );

export const markUserActivitiesRead = async (ids: string[], session: Session) =>
  api.post({
    endpoint: "user-activities/mark-read",
    data: { ids },
    session,
  });

export const useMarkActivitiesRead = (session: Session) =>
  useMutation((data: { ids: string[] }) =>
    markUserActivitiesRead(data.ids, session)
  );
