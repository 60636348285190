import React from "react";
import styled from "styled-components";
import { Text } from "../../components";

const Root = styled.div`
  display: grid;
  gap: 1rem;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.color.cyan};
`;

type Props = {
  label: string;
  value: React.ReactNode;
  style?: React.CSSProperties;
};

export const Stat = ({ label, value, style }: Props) => (
  <Root style={style}>
    <Text>{label}</Text>
    <Value>{value}</Value>
  </Root>
);
