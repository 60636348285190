import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

import type { Color, FontSize } from "../../theme/types";

type Type = "span" | "label";

type Props = {
  align?: "left" | "right" | "center";
  className?: string;
  code?: boolean;
  color?: Color;
  children?: ReactNode;
  ellipsis?: boolean;
  htmlFor?: string;
  onClick?: () => void;
  size?: FontSize;
  title?: string;
  transform?: "capitalize" | "lowercase" | "uppercase";
  type?: Type;
  weight?: number;
  linkColor?: Color;
  dotted?: boolean;
};

const defaultProps: {
  color: Color;
  type: Type;
} = {
  color: "inherit",
  type: "span",
};

const ellipsisStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const textStyles = css<Props>`
  color: ${({ theme, color }) => (color ? theme.color[color] : "inherit")};
  font-family: ${({ code, theme }) => code && theme.fontMonospace};
  font-size: ${({ size, theme }) => (size ? theme.fontSize[size] : "inherit")};
  font-weight: ${({ weight }) => weight};
  text-transform: ${({ transform }) => transform};
  ${({ align }) => (align ? `text-align: ${align}` : "")};
  ${({ ellipsis }) => ellipsis && ellipsisStyles};
  ${({ dotted }) => dotted && "text-decoration: underline dotted"};
  line-height: 1.5;
`;

const TextStyled = styled.label<Props>`
  ${textStyles};

  a {
    color: ${({ linkColor, theme }) =>
      linkColor ? theme.color[linkColor] : theme.color.cyan};
  }
`;

export const Text = ({ type, ...rest }: Props) => (
  <TextStyled as={type} {...rest} />
);

Text.defaultProps = defaultProps;
