import React, { useEffect, useState } from "react";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { NextPageWithLayout } from "../types/next";
import {
  QueryClient,
  QueryClientProvider,
  DehydratedState,
  Hydrate,
} from "@tanstack/react-query";
import { ThemeProvider } from "styled-components";
import { Tooltip } from "../components";
import { theme } from "../theme";
import { FormApiErrorProvider } from "../components/Form/FormApiErrorProvider";
import ProgressBar from "nextjs-progressbar";
import { ToastContainer } from "../components/Toast";
import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
import "../components/Calendar/styles.css";
import { GlobalStyle } from "../theme/global-styles";
import { handleClientError } from "../modules/api/error";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useRouter } from "next/router";
import getConfig from "next/config";
import { Session } from "next-auth";

const { publicRuntimeConfig } = getConfig();

if (typeof window !== "undefined") {
  // extracts values after '#id='
  const maybeDistinctID = window.location.hash.substring(4);

  if (maybeDistinctID) {
    // clear values after hash
    const url = new URL(window.location.href);
    url.hash = "";
    window.history.replaceState({}, document.title, url.toString());
  }

  posthog.init(publicRuntimeConfig.posthogApiKey, {
    api_host: "https://app.posthog.com",

    ...(typeof maybeDistinctID === "string" && maybeDistinctID
      ? {
          bootstrap: {
            distinctID: maybeDistinctID,
          },
        }
      : {}),

    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") {
        posthog.opt_out_capturing();
      }
    },
  });
}

type AppPropsWithLayout<T> = AppProps<T> & {
  Component: NextPageWithLayout<any>;
};

type CustomPageProps = {
  sessionData?: Session;
  dehydratedState: DehydratedState;
};

export default function MyApp({
  Component,
  pageProps,
}: AppPropsWithLayout<CustomPageProps>) {
  const session = pageProps.sessionData;
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  const [queryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          onError: handleClientError,
        },
        mutations: {
          onError: handleClientError,
        },
      },
    })
  );

  useEffect(() => {
    const handleRouteChange = () => posthog.capture("$pageview");

    router.events.on("routeChangeComplete", handleRouteChange);

    const userEmail = session?.user?.email;
    const userName = session?.user?.name;
    if (userEmail) {
      posthog.identify(userEmail, {
        name: userName,
        email: userEmail,
      });
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <PostHogProvider client={posthog}>
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={theme}>
              <GlobalStyle />
              <ProgressBar
                showOnShallow={false}
                options={{ showSpinner: false }}
              />
              <FormApiErrorProvider>
                <Tooltip.Provider>
                  {getLayout(<Component {...pageProps} />, pageProps)}
                </Tooltip.Provider>
              </FormApiErrorProvider>
              <ToastContainer />
            </ThemeProvider>
          </Hydrate>
        </PostHogProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}
