type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export function omit<T, K extends keyof any>(obj: T, keys: K[]): Omit<T, K> {
  const result: Partial<T> = {};

  for (const key in obj) {
    if (!keys.includes(key as any)) {
      result[key as keyof T] = obj[key];
    }
  }

  return result as Omit<T, K>;
}
