import { createGlobalStyle, css } from "styled-components";

const scrollbars = css`
  /* Firefox */
  * {
    scrollbar-color: ${({ theme }) => theme.color.selection}
      ${({ theme }) => theme.color.gray1};
    scrollbar-width: thin;
  }

  /* WebKit / Blink */
  ::-webkit-scrollbar {
    width: 10px !important;
    height: 10px !important;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.backgroundLighter} !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 0 !important;
    background: ${({ theme }) => theme.color.selection} !important;
  }

  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.color.gray1} !important;
  }
`;

const toastify = css`
  /* Library: Toastify */
  --toastify-font-family: ${({ theme }) => theme.font};
  --toastify-color-dark: ${({ theme }) => theme.color.backgroundLighter};
  --toastify-color-info: ${({ theme }) => theme.color.cyan};
  --toastify-color-success: ${({ theme }) => theme.color.green};
  --toastify-color-warning: ${({ theme }) => theme.color.orange};
  --toastify-color-error: ${({ theme }) => theme.color.red};

  .Toastify__toast-container--top-right {
    top: 5.5rem;
  }
`;

const nProgressBar = css`
  #nprogress .bar {
    background: ${({ theme }) => theme.color.pink};
    top: ${({ theme }) => theme.topbar.height};
    opacity: 0.8;
    z-index: 0;
  }

  #nprogress .peg {
    box-shadow: 0 0 10px ${({ theme }) => theme.color.pink};
    transform: none;
    width: 300px;
  }
`;

export const GlobalStyle = createGlobalStyle`
${toastify}
${scrollbars}
${nProgressBar}


/* @TODO: the current a11y focus style is excessive and intrusive */
/* it applies box shadow for too many items, like whole tabbed page */
*:focus {
  box-shadow: none !important;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: ${({ theme }) => theme.font};
  width: 100%;
}

body {
  background-color: #1f2029;
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
}

a {
  color: inherit;
  text-decoration: none;
}

#__next {
  display: flex;
  height: 100%;
}

.u-select {
  background: ${({ theme }) => theme.color.black70};
}
`;
