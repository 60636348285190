import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Activity } from "@styled-icons/bootstrap/Activity";
import { Button } from "@questdb/react-components";
import { Drawer, Link, Select, Text, UnreadItemsIcon } from "../../components";
import { StoreKey } from "../../utils/localStorage";
import { useQueryClient } from "@tanstack/react-query";
import {
  useMarkActivitiesRead,
  useUnreadUserActivities,
  useUserActivities,
} from "./api";
import { activities } from "./activities";
import { getValue, groupBy, setValue } from "../../utils";
import { Session } from "../../server/auth/api";
import type {
  Activity as ActivityType,
  ActivityUI,
  ShowOptions,
} from "./types";
import { ActivityGroup } from "./activity-group";
import NextLink from "next/link";

const Items = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 20rem);
`;

const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-items: flex-end;
  padding: 2rem;
  gap: 1rem;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

type Props = {
  session: Session;
};

export const ActivityLog = ({ session }: Props) => {
  const queryClient = useQueryClient();
  const savedFilter = getValue(StoreKey.ACTIVITY_LOG_FILTER);
  const [showOptions, setShowOptions] = React.useState<ShowOptions>(
    savedFilter !== "" ? (savedFilter as ShowOptions) : "all"
  );
  const [open, setOpen] = useState(false);

  const unreadActivityQuery = useUnreadUserActivities(session, {
    refetchInterval: 5000,
    enabled: !open,
  });

  const activityQuery = useUserActivities(session, showOptions, {
    enabled: open,
  });

  const markActivityAsRead = useMarkActivitiesRead(session);

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(StoreKey.ACTIVITY_LOG_FILTER, e.target.value);
    setShowOptions(e.target.value as ShowOptions);
  };

  useEffect(() => {
    if (open) {
      void activityQuery.refetch();
    }
  }, [showOptions, open]);

  const mappedActivities: (ActivityType & ActivityUI)[] = (
    activityQuery.data ?? []
  )
    .filter((activity) => (showOptions === "unread" ? !activity.read : true))
    .map((activity) => ({
      ...activity,
      ...activities[activity.activity_type],
      created_at_date: new Date(activity.created_at).toLocaleDateString(),
    }));

  const groupedActivities = groupBy(mappedActivities, "created_at_date");

  return (
    <Drawer
      title="Activity Log"
      trigger={
        <Button
          prefixIcon={<Activity size="18px" />}
          skin="transparent"
          dataHook="activity-log-button"
        >
          <UnreadItemsIcon
            count={unreadActivityQuery?.data?.length}
            label="Activity"
          />
        </Button>
      }
      withCloseButton
      onOpenChange={(isOpen: boolean) => {
        setOpen(isOpen);
        // Mark read when drawer is closed.
        if (
          !isOpen &&
          activityQuery?.data &&
          unreadActivityQuery?.data &&
          unreadActivityQuery.data.length > 0
        ) {
          markActivityAsRead.mutate(
            { ids: activityQuery.data.map(({ id }) => id) },
            {
              onSuccess: () =>
                queryClient.invalidateQueries(["unread-user-activities"]),
            }
          );
        }
      }}
    >
      <Filters>
        <Text>Show:</Text>

        <Select
          defaultValue={showOptions}
          name="show"
          onChange={handleFilterChange}
          options={[
            {
              label: "All",
              value: "all",
            },
            {
              label: "Unread",
              value: "unread",
            },
          ]}
        />
      </Filters>

      <Items>
        {Object.keys(groupedActivities).map((date) => (
          <ActivityGroup
            key={date}
            groupedActivities={groupedActivities[date]}
            date={date}
          />
        ))}
      </Items>

      <Footer>
        {Object.keys(groupedActivities).length === 0 && (
          <Text>
            {showOptions === "unread"
              ? "No unread activities"
              : "No user activity"}
          </Text>
        )}

        {showOptions === "unread" && (
          <NextLink href="/activity-log" passHref>
            <Link icon={null}>View all</Link>
          </NextLink>
        )}
      </Footer>
    </Drawer>
  );
};
