import React, { useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { ContainerStyle, Input as UnstyledInput } from "../Input";
import styled from "styled-components";
import { Button } from "@questdb/react-components";
import { EyeSlashFill } from "@styled-icons/bootstrap/EyeSlashFill";
import { Clipboard } from "@styled-icons/bootstrap/Clipboard";
import { ArrowRepeat } from "@styled-icons/bootstrap/ArrowRepeat";
import { Pencil } from "@styled-icons/bootstrap/Pencil";
import generator from "generate-password";
import { makeHandleCopy } from "../Copyable";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  visible?: boolean;
  autoFocus?: boolean;
};

const Root = styled.div`
  display: flex;
  width: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
`;

const Input = styled(UnstyledInput)<Props>`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  ${(props) => props.disabled && `opacity: 0.7;`}
`;

const ToggleButton = styled(Button).attrs({
  type: "button",
  skin: "secondary",
})`
  cursor: pointer;
  border-radius: 0;

  // magic number to prevent layout shift
  // on toggle
  min-width: 7.5rem;

  &:last-child {
    border-top-right-radius: 0.4rem;
    border-bottom-right-radius: 0.4rem;
  }
`;

const InputMimic = styled.div`
  ${ContainerStyle}
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const Password = ({
  name,
  visible,
  autoFocus,
  autoComplete,
  ...rest
}: Props) => {
  const { setFocus, setValue, watch, control } = useFormContext();
  const [passwordShown, setPasswordShown] = useState(visible);
  const value = watch(name);

  useEffect(() => {
    if (autoFocus) {
      setFocus(name);
    }
  }, []);

  return (
    <Root>
      <Controller
        control={control}
        name={name}
        render={({ field }) =>
          passwordShown ? (
            <Input {...field} {...rest} type="text" />
          ) : (
            <InputMimic>{"•".repeat(value.length)}</InputMimic>
          )
        }
      />

      {passwordShown && (
        <ToggleButton
          onClick={() => {
            setValue(
              name,
              generator.generate({
                length: 16,
                numbers: true,
              }),
              {
                shouldDirty: true,
              }
            );
            setPasswordShown(true);
          }}
          prefixIcon={<ArrowRepeat size="18px" />}
        >
          Randomize
        </ToggleButton>
      )}
      <ToggleButton
        onClick={makeHandleCopy(value)}
        prefixIcon={<Clipboard size="18px" />}
      >
        Copy
      </ToggleButton>

      <ToggleButton
        onClick={() => setPasswordShown(!passwordShown)}
        data-hook="password-toggle"
        prefixIcon={
          passwordShown ? <EyeSlashFill size="18px" /> : <Pencil size="18px" />
        }
      >
        {passwordShown ? "Hide" : "Edit"}
      </ToggleButton>
    </Root>
  );
};
