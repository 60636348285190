import React from "react";
import styled from "styled-components";
import { Text } from "../../components";

type Props = {
  label: React.ReactNode;
  afterLabel?: React.ReactNode;
  value: React.ReactNode;
  direction?: "row" | "column";
  className?: string;
  gap?: string;
};

const Root = styled.div<Pick<Props, "direction" | "gap">>`
  display: grid;
  gap: 1rem;
  grid-template-columns: ${({ direction }) =>
    direction === "column" ? "1fr" : "120px auto"};
  align-items: center;
  padding: ${({ gap }) => gap};
  margin: 0;

  &:not(:last-child) {
    border-bottom: 1px ${({ theme }) => theme.color.selectionDarker} solid;
  }
`;

const Label = styled.div`
  color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  display: flex;
  align-items: center;
`;

const AfterLabel = styled.span`
  margin-left: auto;
`;

const Value = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.color.cyan};
`;

export const Property = ({
  label,
  afterLabel,
  value,
  direction = "row",
  className,
  gap = "1.2rem 2rem",
}: Props) => (
  <Root as="dl" direction={direction} className={className} gap={gap}>
    <Label as="dt">
      {label}
      {afterLabel && <AfterLabel>{afterLabel}</AfterLabel>}
    </Label>
    <Value as="dd">
      <Text>{value}</Text>
    </Value>
  </Root>
);
