import type { Activity as ActivityType, ActivityUI } from "./types";
import React from "react";
import { Drawer, Text } from "../../components";
import { Activity } from "./activity";

export const ActivityGroup = ({
  groupedActivities,
  date,
}: {
  groupedActivities: (ActivityType & ActivityUI)[];
  date: string;
}) => (
  <>
    <Drawer.GroupHeader>
      <Text>{date}</Text>
    </Drawer.GroupHeader>

    {groupedActivities.map((item: ActivityType & ActivityUI) => (
      <Activity activity={item} key={item.id} />
    ))}
  </>
);
