import React, { useState } from "react";

import { SupportedSyntax } from "../../consts/syntaxLanguages";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";

import javascript from "react-syntax-highlighter/dist/cjs/languages/prism/javascript";
import go from "react-syntax-highlighter/dist/cjs/languages/prism/go";
import java from "react-syntax-highlighter/dist/cjs/languages/prism/java";
import python from "react-syntax-highlighter/dist/cjs/languages/prism/python";
import c from "react-syntax-highlighter/dist/cjs/languages/prism/c";
import csharp from "react-syntax-highlighter/dist/cjs/languages/prism/csharp";
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
import rust from "react-syntax-highlighter/dist/cjs/languages/prism/rust";

import styled from "styled-components";
import { codeTagProps, theme } from "./theme";
import { Button } from "@questdb/react-components";
import { EyeFill } from "@styled-icons/bootstrap/EyeFill";
import { EyeSlash } from "@styled-icons/bootstrap/EyeSlash";

type HighlightedSyntax = Exclude<SupportedSyntax, "json">;
const syntaxes: Record<HighlightedSyntax, any> = {
  js: javascript,
  go,
  java,
  python,
  c,
  csharp,
  bash,
  rust,
};

Object.entries(syntaxes).forEach(([alias, syntax]) => {
  SyntaxHighlighter.registerLanguage(alias, syntax);
});

export type Props = {
  language?: SupportedSyntax;
  wrapLongLines?: boolean;
  showLineNumbers?: boolean;
  source: string;
  initiallyHidden?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
  className?: string;
};

const Root = styled.div`
  display: flex;
  gap: 4rem;
  padding: 1rem;
  flex-direction: column;
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Actions = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

const Highlight = styled(SyntaxHighlighter)`
  width: 100%;
  padding: 0;
`;

export const CodeBlock = ({
  showLineNumbers = true,
  language,
  wrapLongLines,
  source,
  initiallyHidden = false,
  actions,
  className,
}: Props) => {
  const [hidden, setHidden] = useState(initiallyHidden);

  const renderActions = [
    ...(Array.isArray(actions) ? actions : [actions])
      .filter(Boolean)
      .map((action) => ({
        visible: true,
        view: () => action,
      })),

    {
      visible: initiallyHidden,
      view: () => (
        <Button
          title="Toggle password visibility"
          skin="secondary"
          onClick={() => setHidden(!hidden)}
          prefixIcon={
            hidden ? <EyeFill size="18px" /> : <EyeSlash size="18px" />
          }
        >
          {hidden ? "Show" : "Hide"}
        </Button>
      ),
    },
  ].filter(({ visible }) => visible);

  return (
    <Root className={className}>
      <Highlight
        PreTag="span"
        wrapLongLines={wrapLongLines}
        language={language}
        showLineNumbers={showLineNumbers}
        style={theme}
        codeTagProps={codeTagProps}
      >
        {hidden ? source.replace(/[^\s]/g, "•") : source}
      </Highlight>

      {renderActions.length > 0 && (
        <Actions>
          {renderActions.map(({ view }, index) => (
            <React.Fragment key={index}>{view()}</React.Fragment>
          ))}
        </Actions>
      )}
    </Root>
  );
};
