import React, { useState } from "react";
import styled from "styled-components";
import {
  PersonFill,
  DoorOpen,
  CreditCardFill,
  QuestionCircleFill,
} from "@styled-icons/bootstrap";
import { Slack } from "@styled-icons/bootstrap/Slack";
import { Button } from "@questdb/react-components";
import { isAdmin, User } from "./user";
import { Session } from "../server/auth/api";
import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  autoUpdate,
} from "@floating-ui/react";
import { offset, shift } from "@floating-ui/dom";
import { Text } from "../components/Text";
import { useRouter } from "next/router";

const UserButton = styled(Button)`
  pointer: cursor;
  &:focus {
    box-shadow: none;
  }
`;

const Content = styled.div`
  display: grid;
  gap: 0.5rem;
  min-width: 22rem;
  background: ${({ theme }) => theme.color.backgroundLighter};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 5px 5px 0 ${({ theme }) => theme.color.black40};
  padding: 1rem 0;
`;

const MenuItem = styled.div`
  border-radius: 3px;
  display: flex;
  gap: 1.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  user-select: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.comment};
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.8;
  }

  &:focus {
    background: ${({ theme }) => theme.color.comment};
    cursor: pointer;
  }
`;

const Divider = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.color.selection};
  margin: 0.5rem 0;
`;

type Props = {
  session: Session;
  user: User;
};

export const UserMenu = ({ session, user }: Props) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(20), shift({ padding: 10 })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const navigate = (path: string) => () => {
    router.push(path);
    setIsOpen(false);
  };

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        <UserButton
          skin="transparent"
          data-hook="user-menu-button"
          prefixIcon={<PersonFill size={18} />}
        >
          {user.first_name} {user.last_name}
        </UserButton>
      </div>

      {isOpen && (
        <Content
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {isAdmin(session) && (
            <MenuItem onClick={navigate("/billing")}>
              <CreditCardFill size="18" />
              <Text>Billing</Text>
            </MenuItem>
          )}

          <MenuItem onClick={navigate("/docs")}>
            <QuestionCircleFill size="18" />
            <Text>Documentation</Text>
          </MenuItem>

          <MenuItem>
            <Slack size={16} />
            <a
              href="https://slack.questdb.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              QuestDB Slack
            </a>
          </MenuItem>

          <Divider />

          <MenuItem onClick={navigate("/auth/signout")}>
            <DoorOpen size={18} />
            <Text>Logout</Text>
          </MenuItem>
        </Content>
      )}
    </>
  );
};
