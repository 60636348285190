import styled from "styled-components";

export const DrawerGroupHeader = styled.div`
  display: flex;
  padding: 5rem 2rem 2rem;
  border-top: 1px solid #2d3046;
  opacity: 0.7;
  font-family: ${({ theme }) => theme.font};

  &:first-child {
    padding: 2rem;
  }
`;
