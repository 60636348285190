import { ActivityUI, Type } from "./types";
import { BadgeType } from "../../components";

export const activities: { [key in Type]: ActivityUI } = {
  instance_creating: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Creating instance <%= db_name %>",
  },
  instance_created: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been created",
  },
  instance_updating: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Updating instance <%= db_name %>",
  },
  instance_updated: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been edited",
  },
  instance_deleting: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Deleting instance <%= db_name %>",
  },
  instance_deleted: {
    badge: BadgeType.ERROR,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been deleted",
  },
  instance_restarting: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Restarting instance <%= db_name %>",
  },
  instance_starting: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Starting instance <%= db_name %>",
  },
  instance_started: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been started",
  },
  instance_stopping: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Pausing instance <%= db_name %>",
  },
  instance_stopped: {
    badge: BadgeType.ERROR,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been paused",
  },
  instance_restarted: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been restarted",
  },
  instance_restoring: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>",
    text: "Restoring instance <%= db_name %> from snapshot",
  },
  instance_restored: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>",
    text: "Instance <%= db_name %> has been successfully restored.",
  },
  volume_snapshot_creating: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Creating snapshot <%= snapshot_label %>",
  },
  volume_snapshot_created: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Snapshot <%= snapshot_label %> has been created",
  },
  volume_snapshot_deleting: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Deleting snapshot <%= snapshot_label %>",
  },
  volume_snapshot_deleted: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Snapshot <%= snapshot_label %> has been deleted",
  },
  snapshot_schedule_creating: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Creating snapshot schedule <%= schedule_name %>",
  },
  snapshot_schedule_created: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Snapshot schedule <%= schedule_name %> has been created",
  },
  snapshot_schedule_updating: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Updating snapshot schedule <%= schedule_name %>",
  },
  snapshot_schedule_updated: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Snapshot schedule <%= schedule_name %> has been updated",
  },
  snapshot_schedule_deleting: {
    badge: BadgeType.INFO,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Deleting snapshot schedule <%= schedule_name %>",
  },
  snapshot_schedule_deleted: {
    badge: BadgeType.SUCCESS,
    url: "/instances/<%= db_id %>/snapshots",
    text: "Snapshot schedule <%= schedule_name %> has been deleted",
  },
};
