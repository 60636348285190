import React from "react";
import { color } from "../theme";

type Props = {
  current: number;
  max: number;
  diameter?: number;
  className?: string;
};

export const ProgressCircle = ({
  current,
  max,
  diameter = 16,
  className,
}: Props) => {
  const strokeWidth = 2;
  const radius = diameter / 2;
  const angle = (Math.max(0, current) / max) * 360;
  const endX = radius + radius * Math.sin((angle * Math.PI) / 180);
  const endY = radius - radius * Math.cos((angle * Math.PI) / 180);
  const largeArcFlag = angle > 180 ? 1 : 0;

  if (current >= max) {
    return (
      <svg width={diameter} height={diameter} className={className}>
        <circle cx={radius} cy={radius} r={radius} fill={color.green} />
      </svg>
    );
  }

  return (
    <svg width={diameter} height={diameter} className={className}>
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        stroke="grey"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <path
        d={`M ${radius} ${radius} L ${radius} 0 A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`}
        fill={color.green}
      />
    </svg>
  );
};
