export const sanitizeUrl = (url: unknown): string => {
  if (typeof url !== "string") {
    return "";
  }

  let result = url;
  if (!result.startsWith("https://") && !/^.+:\/\//.test(url)) {
    result = `https://${result}`;
  }

  if (!result.endsWith("/")) {
    result = `${result}/`;
  }

  return result;
};
