import React from "react";
import styled from "styled-components";
import { Button } from "@questdb/react-components";
import { FeedbackDialog } from "../../modules/feedback-dialog";
import { ActivityLog } from "../../modules/activity-log";
import { News } from "../../modules/news";
import { Session } from "../../server/auth/api";
import Link from "next/link";
import { CloudLogo } from "../CloudLogo";
import { UserMenu } from "../../modules/user-menu";
import { ChatRightFill } from "@styled-icons/bootstrap/ChatRightFill";
import { User } from "../../modules/user";
import { Progress } from "../../modules/onboarding/progress";

const Root = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 2rem 0 1rem;
  z-index: 1;
  border-bottom: 1px solid ${({ theme }) => theme.color.background};
  height: ${({ theme }) => theme.topbar.height};
  background-color: ${({ theme }) => theme.color.backgroundLighter};
  box-shadow: 0 1px 0 0 ${({ theme }) => theme.color.black40};
`;

const SideContent = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

type Props = {
  session: Session;
  TopBarNavigator?: React.ReactNode;
  user?: User;
};

export const TopBar = ({ user, session, TopBarNavigator }: Props) => (
  <Root>
    <Link href="/clusters" passHref>
      <a>
        <CloudLogo width={170} height={40} />
      </a>
    </Link>
    {TopBarNavigator}
    <SideContent>
      {user && (
        <>
          <Progress session={session} />

          <FeedbackDialog
            category="generic"
            user={user}
            trigger={({ setOpen }) => (
              <Button
                skin="transparent"
                prefixIcon={<ChatRightFill size={16} />}
                dataHook="feedback-dialog-button"
                onClick={() => setOpen(true)}
              >
                Get in Touch
              </Button>
            )}
          />

          <News />

          <ActivityLog session={session} />

          <UserMenu session={session} user={user} />
        </>
      )}
    </SideContent>
  </Root>
);
