import { toast } from "../../components/Toast";

export class ApiError {
  message: string;
  status: number;

  constructor({ message, status }: { message: string; status: number }) {
    this.message = message;
    this.status = status;
  }
}

export const handleClientError = (error: unknown) => {
  if (error instanceof ApiError) {
    toast.error(error.message || "An error occurred");

    if (error.status === 401) {
      location.pathname = "/auth/signout";
    }
  }
};
