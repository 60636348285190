import React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import type {
  AccordionSingleProps,
  AccordionMultipleProps,
  AccordionTriggerProps,
  AccordionContentProps,
} from "@radix-ui/react-accordion";
import styled from "styled-components";
import { ChevronUp } from "@styled-icons/bootstrap/ChevronUp";

export const Accordion = (
  props: AccordionSingleProps | AccordionMultipleProps
) => <AccordionPrimitive.Accordion {...props} />;

Accordion.Item = AccordionPrimitive.Item;

const Trigger: React.FunctionComponent<AccordionTriggerProps> = ({
  children,
  ...props
}) => (
  <AccordionPrimitive.Header asChild>
    <AccordionPrimitive.Trigger {...props} asChild>
      {children}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
);

Accordion.Trigger = Trigger;

const Content: React.FunctionComponent<AccordionContentProps> = ({
  children,
  ...props
}) => (
  <AccordionPrimitive.Content {...props}>{children}</AccordionPrimitive.Content>
);

Accordion.Content = Content;

const ChevronUpIcon = styled(ChevronUp)`
  transition: transform 0.2s linear;
  [data-state=open] & { transform: rotate(180deg); },
`;

Accordion.ChevronUpIcon = ChevronUpIcon;
