import React, { KeyboardEvent, useEffect } from "react";
import {
  useForm,
  FormProvider,
  FieldValues,
  SubmitHandler,
  UseFormProps,
  WatchObserver,
  Path,
  DeepMap,
  DeepPartial,
  DefaultValues,
  UseFormReturn,
  FieldError,
} from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Schema } from "joi";
import { FormItem } from "./FormItem";
import { FormInput } from "./FormInput";
import { FormCheckbox } from "./FormCheckbox";
import { FormRadio } from "./FormRadio";
import { FormSelect } from "./FormSelect";
import { FormGroup } from "./FormGroup";
import styled from "styled-components";
import { FormSubmit } from "./FormSubmit";
import { FormCancel } from "./FormCancel";
import { useFormApiError } from "./FormApiErrorProvider";
import { FormTextArea } from "./FormTextArea";
import { Password } from "./password";
import { notify } from "../../modules/instances/utils";

// @TODO: remove this, there should be no styling on the root. We should use <Box> or dedicated styled-component instead
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
type DirtyChangeObserver<T = Record<string, any>> = (
  isDirty: boolean,
  dirtyFields:
    | Partial<Readonly<DeepMap<DeepPartial<T>, boolean>>>
    | Record<string, string>
) => void;

export type FormProps<FormValues extends FieldValues> = {
  name: string;
  method?: HTMLFormElement["method"];
  mode?: UseFormProps["mode"];
  onSubmit?: SubmitHandler<FormValues>;

  /** @TODO: this should really be onSubmit, but we need to update all the forms
   * it should be done in a separate PR
   */
  onSubmitWithContext?: (
    context: UseFormReturn<FormValues>
  ) => SubmitHandler<FormValues>;
  onChange?: WatchObserver<FormValues>;
  onDirtyChange?: DirtyChangeObserver<FormValues>;
  children: React.ReactNode;
  validationSchema?: Schema;
  defaultValues?: DefaultValues<FormValues>;
  className?: string;
  preventSubmitOnEnter?: boolean;
  onError?: (error: FieldError) => void;
};

export const Form = <FormValues extends FieldValues = FieldValues>({
  name,
  method = "post",
  mode = "onSubmit",
  onSubmit,
  onSubmitWithContext,
  onChange,
  onDirtyChange,
  children,
  validationSchema,
  defaultValues,
  className,
  preventSubmitOnEnter,
  onError,
}: FormProps<FormValues>) => {
  let props: UseFormProps<FormValues> = { mode };
  const { apiErrors, clearApiErrors } = useFormApiError();

  if (defaultValues) {
    props.defaultValues = defaultValues;
  }

  if (validationSchema) {
    props.resolver = joiResolver(validationSchema);
  }

  const methods = useForm<FormValues>(props);

  const handleKeyDown = (event: KeyboardEvent<HTMLFormElement>) => {
    if (
      preventSubmitOnEnter &&
      event.key === "Enter" &&
      event.target instanceof HTMLElement &&
      event.target.nodeName !== "TEXTAREA"
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (onChange) {
      methods.watch(onChange);
    }

    return () => {
      clearApiErrors(name);
    };
  }, []);

  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(methods.formState.isDirty, methods.formState.dirtyFields);
    }
  }, [methods.formState]);

  useEffect(() => {
    if (apiErrors[name]) {
      Object.keys(apiErrors[name]).forEach((value: string) => {
        const itemKey = value as Path<FormValues>;
        return methods.setError(itemKey, {
          type: "manual",
          message: `${apiErrors[name][itemKey].message}`,
        });
      });
    }
  }, [apiErrors]);

  const handleSubmit = async (e: React.BaseSyntheticEvent) => {
    e.stopPropagation();

    if (typeof onSubmit === "function") {
      return await methods.handleSubmit(onSubmit)(e);
    }

    if (typeof onSubmitWithContext === "function") {
      return await methods.handleSubmit(onSubmitWithContext(methods))(e);
    }
  };

  if (Object.keys(methods.formState.errors).length > 0) {
    console.log("Schema validation errors", methods.formState.errors);
    Object.values(methods.formState.errors).forEach((error) => {
      onError?.(error);
    });
  }

  return (
    <Wrapper className={className}>
      <FormProvider {...methods}>
        <form
          name={name}
          onSubmit={handleSubmit}
          method={method}
          onKeyDown={handleKeyDown}
        >
          {children}
        </form>
      </FormProvider>
    </Wrapper>
  );
};

Form.Item = FormItem;
Form.Input = FormInput;
Form.Checkbox = FormCheckbox;
Form.Radio = FormRadio;
Form.Select = FormSelect;
Form.Group = FormGroup;
Form.Submit = FormSubmit;
Form.Cancel = FormCancel;
Form.TextArea = FormTextArea;
Form.Password = Password;
