import axios from "axios";
import type { AxiosError, Method } from "axios";
import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";
import { sanitizeUrl } from "../../utils/sanitize-url";
import { Session } from "../../server/auth/api";
import { ApiError } from "./error";

const { publicRuntimeConfig } = getConfig();

const axiosApi = axios.create({
  baseURL: `${sanitizeUrl(publicRuntimeConfig.baseURL)}api/`,
});

axiosApi.defaults.withCredentials = true;

type Request = {
  method: Method;
  endpoint: string;
  data?: object | string | undefined;
  params?: object | string | undefined;
  abortSignal?: AbortSignal;
  session: Session;
};

const request = async <Success>({
  method,
  endpoint,
  data,
  params,
  session,
  abortSignal,
}: Request): Promise<Success> => {
  try {
    const response = await axiosApi({
      method,
      url: endpoint,
      data,
      params,
      headers: {
        Authorization: `Bearer ${session.accessToken}`,
        ...(session.organisationId
          ? { "organisation-id": session.organisationId }
          : {}),
      },
      signal: abortSignal,
    });

    return Promise.resolve(response.data);
  } catch (error: unknown) {
    Sentry.captureException(error);

    if ((error as AxiosError).isAxiosError) {
      const err = error as AxiosError;

      return Promise.reject(
        new ApiError({
          message: err.response?.data.message,
          status: err.response?.status ?? 500,
        })
      );
    } else {
      return Promise.reject(error);
    }
  }
};

const makeRequest =
  (method: Method) =>
  <Response = any>(props: Omit<Request, "method">) =>
    request<Response>({ method, ...props });

const api = {
  get: makeRequest("GET"),
  post: makeRequest("POST"),
  put: makeRequest("PUT"),
  patch: makeRequest("PATCH"),
  delete: makeRequest("DELETE"),
};

export default api;

export const isApiError = axios.isAxiosError;
