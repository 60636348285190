import React from "react";

import { AlertDialog } from "../AlertDialog";
import { ForwardRef } from "../ForwardRef";
import { Button } from "@questdb/react-components";
import type { Props as ButtonProps } from "@questdb/react-components/dist/components/Button";
import { Text } from "../Text";
import { Overlay } from "../Overlay";
import { Undo } from "../icons/undo";
import { Card } from "../Card";

type Props = {
  hookPrefix?: string;
  open?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  title: React.ReactNode;
  description?: React.ReactNode;
  actionText: string;
  actionIcon?: React.ReactNode;
  cancelText?: string;
  cancelIcon?: React.ReactNode;
  variant: ButtonProps["skin"];
  children?: React.ReactNode;
  icon?: React.ReactNode;
};

export const ConfirmationDialog = ({
  open,
  onConfirm,
  onCancel,
  title,
  description,
  actionText = "Confirm",
  actionIcon,
  cancelText = "Cancel",
  cancelIcon = <Undo size="18" />,
  variant,
  children,
  icon,
}: Props) => (
  <AlertDialog.Root open={open}>
    {children && (
      <AlertDialog.Trigger asChild>
        <ForwardRef>{children}</ForwardRef>
      </AlertDialog.Trigger>
    )}

    <AlertDialog.Portal>
      <ForwardRef>
        <Overlay primitive={AlertDialog.Overlay} />
      </ForwardRef>

      <AlertDialog.Content>
        <Card>
          <Card.Header title={title} beforeTitle={icon} />

          {description && (
            <Card.Content>
              <Text>{description}</Text>
            </Card.Content>
          )}

          <Card.Footer>
            <AlertDialog.Cancel asChild>
              <Button
                skin="secondary"
                onClick={onCancel}
                prefixIcon={cancelIcon}
                dataHook="confirm-dialog-cancel-button"
              >
                {cancelText}
              </Button>
            </AlertDialog.Cancel>

            <AlertDialog.Action asChild>
              <Button
                onClick={onConfirm}
                skin={variant}
                prefixIcon={actionIcon}
                dataHook="confirm-dialog-confirm-button"
              >
                {actionText}
              </Button>
            </AlertDialog.Action>
          </Card.Footer>
        </Card>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
);
