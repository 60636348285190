import { Award, AwardFill, ChevronRight } from "@styled-icons/bootstrap";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useEffect, useReducer } from "react";
import styled, { css } from "styled-components";
import { ProgressCircle as UnstyledProgressCircle } from "../../components/ProgressCircle";
import { Session } from "../../server/auth/api";
import { Popup } from "./popup";
import { steps } from "./steps";
import { OnboardingStep } from "./types";

const initialState: State = {
  steps: steps.map<OnboardingStep>((step) => ({
    ...step,
    status: "todo",
  })),
  status: "loading",
};

const Status = styled.div`
  font-size: 1rem;
  color: #c3c3c3;
`;

const Trigger = styled.div`
  display: grid;
  grid-template-columns: auto 30px auto;
  align-items: center;
  background: ${({ theme }) => theme.color.background};
  border-radius: 5px;
  padding: 0.7rem 1rem;
  cursor: pointer;
  transition: background 0.1s ease-in-out;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.color.comment};
  }

  > span {
    justify-self: flex-start;
    padding-right: 1rem;
  }
`;

const Suffix = styled.div`
  grid-column: 3;
  grid-row: 1 / span 2;
  transition: all 0.2s ease-in-out;
  justify-self: center;
  font-size: 0.9rem;
`;

const StepTitle = styled.span`
  grid-column: 2;
  grid-row: 1;
  padding: 0.4rem 0;
`;

const StepSubtitle = styled.span`
  grid-column: 2;
  grid-row: 2;
  font-size: 1.1rem;
  opacity: 0.9;
`;

const stepTodoCss = css`
  &:hover {
    background: ${({ theme }) => theme.color.background};

    ${Suffix} {
      opacity: 1;
      transform: translateX(0);
    }
  }

  ${Suffix} {
    transform: translateX(-5px);
    opacity: 0.9;
    margin-left: 0;
  }
`;

const StepLink = styled.a<{ done: boolean }>`
  display: grid;
  grid-template-columns: 35px auto 40px;
  grid-template-rows: auto auto;
  gap: 0.2rem 1rem;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem 0 1rem;
  transition: all 0.2s ease-in-out;
  opacity: ${({ done }) => (done ? 0.6 : 1)};
  pointer-events: ${({ done }) => (done ? "none" : "all")};

  ${({ done }) => !done && stepTodoCss}
`;

const awardStyle = css`
  grid-row: 1 / span 2;
  justify-self: center;
  width: 20px;
  height: 20px;
`;

const Indicator = styled(Award)`
  ${awardStyle}
  fill: ${({ theme }) => theme.color.gray2};
`;

const IndicatorDone = styled(AwardFill)`
  ${awardStyle}
  fill: #FFD700;
`;

const Header = styled.div`
  gap: 0.2rem 1rem;
  padding: 0 0.4rem 2rem;
  align-items: center;

  h4 {
    margin: 0;
    padding-bottom: 0.4rem;
  }

  p {
    margin: 0;
    font-size: 1.1rem;
    line-height: 1.5;
  }
`;

const ProgressCircle = styled(UnstyledProgressCircle)`
  justify-self: center;
`;

type State = {
  steps: OnboardingStep[];
  status: "loading" | "idle" | "completed";
};

const reducer = (state: typeof initialState, action: Partial<State>) => ({
  ...state,
  ...action,
});

const isTodo = (step: OnboardingStep) => step.status === "todo";

type Props = {
  session: Session;
};

export const Progress = ({ session }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { route } = useRouter();

  useEffect(
    () => {
      Promise.all(
        state.steps.map(async (step) => ({
          ...step,
          status: (await step.completed(session))
            ? "done"
            : ("todo" as OnboardingStep["status"]),
        }))
      ).then((checkedSteps) => {
        const isComplete = checkedSteps.every((step) => step.status === "done");
        dispatch({
          status: isComplete ? "completed" : "idle",
          steps: checkedSteps,
        });
      });
    },

    // only run on route change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // re-check steps on route change. Needed for cases like `/instances/create` -> `/instances/:id` when instance is created and one step is done
      route,
    ]
  );

  const incompleteSteps =
    state.steps.length - state.steps.filter(isTodo).length;

  if (state.status !== "idle") {
    return null;
  }

  return (
    <Popup
      trigger={
        <Trigger>
          <span>What&apos;s next</span>

          <Status>
            {incompleteSteps} / {state.steps.length}
          </Status>
          <ProgressCircle current={incompleteSteps} max={state.steps.length} />
        </Trigger>
      }
    >
      <Header>
        <h4>Complete your QuestDB setup</h4>
        <p>Get up and running with these steps</p>
      </Header>

      {state.steps.map((step, index) => {
        const done = step.status === "done";

        return (
          <Link href={step.route} key={step.route} passHref={!done}>
            <StepLink as={done ? "span" : "a"} key={index} done={done}>
              {done ? <IndicatorDone /> : <Indicator />}
              <StepTitle>{step.title}</StepTitle>
              <StepSubtitle>{step.subtitle}</StepSubtitle>
              <Suffix>{done ? "Done!" : <ChevronRight size={10} />}</Suffix>
            </StepLink>
          </Link>
        );
      })}
    </Popup>
  );
};
