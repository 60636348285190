import { Session } from "../../server/auth/api";
import {
  MutationOptions,
  QueryClient,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { BillingDashboard, Credit, PaymentMethod, TaxData } from "./types";
import api from "../api";
import { ResponseError } from "../api/types";
import { useContext } from "react";
import { ThemeContext } from "styled-components";

type PaymentMethodSetupIntent = {
  client_secret: string;
};

export const postSetBillingProvider = async (session: Session) =>
  api.post({ endpoint: `billing/set-billing-provider`, session });

export const getPaymentMethods = async (session: Session) =>
  api.get<PaymentMethod[]>({ endpoint: "billing/payment-methods", session });

export const getTaxId = async (session: Session) =>
  api.get<TaxData>({ endpoint: "billing/tax-id", session });

export const useTaxId = (
  session: Session,
  queryOptions?: {
    enabled?: boolean;
  }
) => useQuery(["billing/tax-id"], () => getTaxId(session), queryOptions);

export const useUpdateTaxId = (
  session: Session,
  queryOptions?: MutationOptions<
    unknown,
    ResponseError,
    Pick<TaxData, "tax_id_value" | "tax_id_type">
  >
) =>
  useMutation(
    (data) => api.patch({ endpoint: `billing/tax-id`, data, session }),
    queryOptions
  );

export const useDeleteTaxId = (session: Session) =>
  useMutation(() => api.delete({ endpoint: `billing/tax-id`, session }));

export const useBillingDashboards = (
  session: Session,
  dashboard_type: "invoices" | "usage"
) => {
  const themeContext = useContext(ThemeContext);

  return useQuery(["billing/dashboards", dashboard_type], () =>
    api.post<BillingDashboard>({
      endpoint: "billing/dashboards",
      session,
      data: {
        dashboard_type,
        color_overrides: [
          // Application background
          {
            name: "White",
            value: themeContext.color.background,
          },
          // Background for selected item
          {
            name: "Primary_light",
            value: themeContext.color.selectionDarker,
          },
          // Standard text
          {
            name: "Gray_dark",
            value: themeContext.color.white,
          },
          // Badges
          {
            name: "Gray_medium",
            value: themeContext.color.selection,
          },
          // Selected text, border
          {
            name: "Primary_medium",
            value: themeContext.color.pink,
          },
          // Borders
          {
            name: "Gray_light",
            value: themeContext.color.background,
          },
          // Background for tiered charges
          {
            name: "Gray_extralight",
            value: themeContext.color.background,
          },
        ],
      },
    })
  );
};

export const createPaymentMethodSetupIntent = async (session: Session) =>
  api.post<PaymentMethodSetupIntent>({
    endpoint: "billing/create_payment_method_setup_intent",
    session,
    data: {
      payment_method_types: ["card", "us_bank_account"],
    },
  });

export const useCreatePaymentMethodSetupIntent = (
  session: Session,
  queryOptions?: {
    refetchOnWindowFocus?: boolean;
    enabled: boolean;
  }
) =>
  useQuery(
    ["billing/create_payment_method_setup_intent"],
    () => createPaymentMethodSetupIntent(session),
    queryOptions
  );

export const usePaymentMethods = (
  session: Session,
  queryOptions?: {
    initialData?: PaymentMethod[];
  }
) =>
  useQuery(
    ["billing/payment-methods"],
    () => getPaymentMethods(session),
    queryOptions
  );

export const prefetchTaxId = async (
  queryClient: QueryClient,
  session: Session
) => queryClient.prefetchQuery(["billing/tax-id"], () => getTaxId(session));

export const prefetchCreatePaymentMethodSetupIntent = async (
  queryClient: QueryClient,
  session: Session
) =>
  queryClient.prefetchQuery(
    ["billing/create_payment_method_setup_intent"],
    () => createPaymentMethodSetupIntent(session)
  );

export const prefetchPaymentMethods = async (
  queryClient: QueryClient,
  session: Session
) =>
  queryClient.prefetchQuery(["billing/payment-methods"], () =>
    getPaymentMethods(session)
  );

export const useSetPaymentMethodAsDefault = (session: Session) =>
  useMutation((id: string) =>
    api.post({ endpoint: `billing/payment-methods/${id}/set-default`, session })
  );

export const useDeletePaymentMethod = (session: Session) =>
  useMutation((id: string) =>
    api.delete({ endpoint: `billing/payment-methods/${id}`, session })
  );

export const getCredits = async (session: Session) =>
  api.get<Credit>({ endpoint: "billing/credits", session });

export const prefetchCredits = async (
  queryClient: QueryClient,
  session: Session
) => queryClient.prefetchQuery(["billing/credits"], () => getCredits(session));

export const useCredits = (session: Session) =>
  useQuery(["billing/credits"], () => getCredits(session), {
    staleTime: Infinity,
  });
