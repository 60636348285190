import React, { useEffect, useState } from "react";
import { Button, Heading } from "@questdb/react-components";
import { Drawer, Text, UnreadItemsIcon } from "../../components";
import { BellFill } from "@styled-icons/bootstrap";
import { useNews } from "./api";
import { getValue, groupBy, setValue, uniq } from "../../utils";
import { NewsItem } from "./types";
import styled from "styled-components";
import { StoreKey } from "../../utils/localStorage";
import ReactMarkdown from "react-markdown";

const Items = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - ${({ theme }) => theme.topbar.height});
`;

const Thumbnail = styled.img`
  max-width: 100%;
  margin: 2rem 0;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 7px 30px -10px ${({ theme }) => theme.color.black};
`;

const NewsText = styled(Text)`
  a {
    color: ${({ theme }) => theme.color.cyan};
  }

  code {
    background-color: ${({ theme }) => theme.color.selection};
    padding: 0.2rem 0.4rem;
    border-radius: 0.2rem;
  }
`;

export const News = () => {
  const readNewsStorageValue = getValue(StoreKey.READ_NEWS);
  const [hasUnreadItems, setHasUnreadItems] = useState(false);
  const newsQuery = useNews();

  const handleOpenChange = (open: boolean) => {
    if (open && newsQuery.data) {
      setValue(
        StoreKey.READ_NEWS,
        JSON.stringify(newsQuery.data.map((newsItem: NewsItem) => newsItem.id))
      );
      setHasUnreadItems(false);
    }
  };

  const groupedNews = groupBy<NewsItem>(newsQuery.data ?? [], "date");

  useEffect(() => {
    if (!newsQuery.data) return;
    if (readNewsStorageValue) {
      setHasUnreadItems(
        newsQuery.data.find((newsItem: NewsItem) => {
          return !JSON.parse(readNewsStorageValue).find(
            (id: string) => id === newsItem.id
          );
        }) !== undefined
      );
    } else {
      setHasUnreadItems(true);
    }
  }, [newsQuery.data]);

  return (
    <Drawer
      title="What's new"
      onOpenChange={handleOpenChange}
      trigger={
        <Button skin="transparent" title="News" dataHook="news-button">
          <UnreadItemsIcon
            icon={<BellFill size="18px" />}
            tick={hasUnreadItems}
            label="News"
          />
        </Button>
      }
      withCloseButton
    >
      <Items>
        {Object.keys(groupedNews).map((date) => (
          <React.Fragment key={date}>
            <Drawer.GroupHeader>
              <Text>{date}</Text>
            </Drawer.GroupHeader>
            {groupedNews[date].map((item: NewsItem, index) => (
              <Drawer.GroupItem key={item.id} direction="column">
                <Heading level={5}>{item.title}</Heading>
                {item.thumbnail &&
                  item.thumbnail.length > 0 &&
                  item.thumbnail[0].thumbnails.large && (
                    <Thumbnail
                      src={item.thumbnail[0].thumbnails.large.url}
                      alt={`${item.title} thumbnail`}
                    />
                  )}
                <NewsText>
                  <ReactMarkdown
                    components={{
                      a: ({ node, children, ...props }) => (
                        <a
                          {...(props.href?.startsWith("http")
                            ? { target: "_blank", rel: "noopener noreferrer" }
                            : {})}
                          {...props}
                        >
                          {children}
                        </a>
                      ),
                    }}
                  >
                    {item.body}
                  </ReactMarkdown>
                </NewsText>
              </Drawer.GroupItem>
            ))}
          </React.Fragment>
        ))}
      </Items>
    </Drawer>
  );
};
