import { Session } from "../server/auth/api";
import { UserRole } from "./organisation/types";

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  first_login: boolean;
  email: string;
  must_change_password: boolean;
  signup_prevented: boolean;
};

export const isAdmin = (session: Session): boolean =>
  session.role === UserRole.ADMIN;
