import React, { useEffect, useState } from "react";
import * as RadixTabs from "@radix-ui/react-tabs";
import Link from "next/link";
import styled from "styled-components";

import { ForwardRef } from "../ForwardRef";

type Tab<T = string> = {
  value?: T;
  href?: string;
  title: React.ReactNode;
  icon?: React.ReactNode;
  hidden?: boolean;
  children?: React.ReactNode;
};

export type Props<Value = string> = {
  defaultValue: Value;
  tabs: Tab<Value>[];
  children?: React.ReactNode;
  centered?: boolean;
  className?: string;
  onChange?: (value: Value) => void;
  gapToContent?: string;
};

const Root = styled(RadixTabs.Root)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const List = styled(RadixTabs.List)<{
  centered?: string;
  gap: Props["gapToContent"];
}>`
  display: flex;
  margin-bottom: ${({ gap }) => gap ?? "2rem"};
  position: relative;
  align-items: center;
  ${({ centered }) => centered && `justify-content: center;`}
`;

const TabsContent = RadixTabs.Content;

const Trigger = styled(RadixTabs.Trigger)`
  appearance: initial;

  > a,
  > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 2rem;
    opacity: 0.8;
    overflow: hidden;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.3rem;
      left: 0;
      bottom: 0;
      background: ${({ theme }) => theme.color.pink};
      transform: translate(0, 0.3rem);
    }

    > svg {
      margin-right: 0.5rem;
    }
  }

  &[aria-selected="true"] a,
  &[aria-selected="true"] span {
    opacity: 1;

    &:before {
      transform: translate(0, 0);
    }
  }

  &:hover a,
  &:hover span {
    opacity: 1;
  }
`;

const Content = styled.span`
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
`;

const getValue = (tab: Tab, fallback: number | string): string =>
  tab.href ?? tab.value ?? fallback.toString();

export const Tabs = <Value extends string>({
  defaultValue,
  tabs,
  children,
  centered,
  className,
  onChange,
  gapToContent,
}: Props<Value>) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const visibleTabs = tabs.filter(({ hidden }) => !hidden);

  return (
    <Root
      className={className}
      value={value}
      onValueChange={(value) => {
        const val = value as unknown as Value;
        if (typeof onChange === "function") {
          onChange(val);
        }
        setValue(val);
      }}
    >
      <List centered={centered?.toString()} gap={gapToContent}>
        {visibleTabs.map((tab, index) => {
          const value = getValue(tab, index);

          const content = (
            <Content>
              {tab.icon}
              {tab.title}
            </Content>
          );

          return (
            <Trigger
              value={value}
              key={value}
              data-hook={`tab-trigger-${value}`}
              asChild
            >
              <ForwardRef>
                {tab.href ? (
                  <Link passHref href={tab.href}>
                    {content}
                  </Link>
                ) : (
                  content
                )}
              </ForwardRef>
            </Trigger>
          );
        })}
      </List>

      {visibleTabs.map((tab, index) => {
        const value = getValue(tab, index);
        return (
          <TabsContent value={value} key={value}>
            {tab.children ?? children}
          </TabsContent>
        );
      })}
    </Root>
  );
};
