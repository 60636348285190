import React from "react";

export const theme: { [key: string]: React.CSSProperties } = {
  'pre[class*="language-"]': {
    fontSize: "inherit",
    fontFamily: "monospace",
    background: "#21222c",
    color: "#f8f8f2",
    display: "flex",
    lineHeight: 1.4,
    overflowX: "auto",
    whiteSpace: "pre-wrap",
  },
  key: {
    color: "#ff79c6",
    fontWeight: "bolder",
  },
  keyword: {
    color: "#ff79c6",
    fontWeight: "bolder",
  },
  "attr-name": {
    color: "#50fa7b",
  },
  selector: {
    color: "#8be9fd",
  },
  comment: {
    color: "#6272A4",
    fontFamily: "inherit",
    fontStyle: "italic",
  },
  "block-comment": {
    color: "#6272A4",
    fontFamily: "inherit",
    fontStyle: "italic",
  },
  "function-name": {
    color: "#F8F8F2",
  },
  "class-name": {
    color: "#F8F8F2",
  },
  doctype: {
    color: "#f1fa8c",
  },
  substr: {
    color: "#f1fa8c",
  },
  namespace: {
    color: "#66d9ef",
  },
  builtin: {
    color: "#50fa7b",
  },
  entity: {
    color: "#FF79C6",
  },
  bullet: {
    color: "#8BE9FD",
  },
  code: {
    color: "#50FA7B",
  },
  addition: {
    color: "#f1fa8c",
  },
  regex: {
    color: "#F1FA8C",
  },
  symbol: {
    color: "#F1FA8C",
  },
  variable: {
    color: "#F8F8F2",
  },
  url: {
    color: "#00bcd4",
  },
  "selector-attr": {
    color: "#FF79C6",
  },
  "selector-pseudo": {
    color: "#FF79C6",
  },
  type: {
    color: "#8BE9FD",
  },
  string: {
    color: "#F1FA8C",
  },
  quote: {
    color: "#E9F284",
  },
  tag: {
    color: "#FF79C6",
  },
  deletion: {
    color: "#FF79C6",
  },
  title: {
    color: "#ff555580",
  },
  section: {
    color: "#F8F8F2",
  },
  "meta-keyword": {
    color: "#50FA7B",
  },
  meta: {
    color: "#50FA7B",
  },
  italic: {
    fontStyle: "italic",
  },
  bold: {
    fontWeight: "bolder",
  },
  function: {
    color: "#50FA7B",
  },
  number: {
    color: "#bd93f9",
  },
  linenumber: {
    color: "rgba(255, 255, 255, 0.4)",
    fontStyle: "normal",
    textAlign: "left",
    minWidth: "2.5rem",
    paddingRight: 0,
  },
};

export const codeTagProps: React.HTMLProps<HTMLElement> = {
  style: {
    fontSize: "inherit",
    fontFamily: "inherit",
    lineHeight: 1.4,
    whiteSpace: "pre-wrap",
  },
};
