import { createContext, PropsWithChildren, useContext, useState } from "react";
import { ApiError } from "../../modules/api/types";
import { omit } from "../../utils";
import { isApiError } from "../../modules/api";

type ContextProps = {
  apiErrors: ApiErrors;
  hasErrors: (formName: string) => boolean;
  setApiErrors: (formName: string, error: ApiError) => void;
  clearApiErrors: (formName: string) => void;
};

type FieldErrorItems = Record<string, ErrorItem>;

type ErrorItem = {
  message: string;
};

/**
 * We keep errors as a key-based object identified by a name.
 * The current use case are API errors that have to be displayed in forms.
 * Errors are cleared as soon as form is unmounted.
 * {
 *   "key": {
 *     "field-key": {
 *         msg: string;
 *       }
 *   }
 * }
 */
type ApiErrors = Record<string, FieldErrorItems>;

const messages: Record<string, string> = {
  ERR_PASSWORD_MISMATCH: "Incorrect password",
};

const apiToFormError = (error: ApiError): FieldErrorItems => {
  const out = {} as FieldErrorItems;
  if (error && isApiError(error) && error.response?.data) {
    const errorResponse = error.response.data;
    Object.keys(errorResponse).forEach((item) => {
      const backendErrorMessage = errorResponse[item].msg;
      out[item] = {
        message: messages[backendErrorMessage] ?? backendErrorMessage,
      };
    });
  }

  return out;
};

const FormApiErrorContext = createContext<ContextProps>({
  apiErrors: {},
  hasErrors: () => false,
  setApiErrors: () => undefined,
  clearApiErrors: () => undefined,
});

export const FormApiErrorProvider = ({ children }: PropsWithChildren<{}>) => {
  const [apiErrors, setApiErrors] = useState<ApiErrors>({});

  const hasErrors = (formName: string) => {
    return (
      apiErrors[formName] !== undefined &&
      Object.values(apiErrors[formName]).length > 0
    );
  };

  const setErrors = (formName: string, error: ApiError) => {
    const formErrors = apiToFormError(error);
    if (formErrors) {
      setApiErrors({ ...apiErrors, [formName]: formErrors });
    }
  };

  const clearErrors = (formName: string) => {
    setApiErrors(omit(apiErrors, [formName]));
  };

  return (
    <FormApiErrorContext.Provider
      value={{
        apiErrors,
        hasErrors,
        setApiErrors: setErrors,
        clearApiErrors: clearErrors,
      }}
    >
      {children}
    </FormApiErrorContext.Provider>
  );
};

export const useFormApiError = () => {
  return useContext(FormApiErrorContext);
};
