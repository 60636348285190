import React, { useEffect, useState } from "react";
import {
  safePolygon,
  useFloating,
  useHover,
  useFocus,
  useInteractions,
  useTransitionStyles,
} from "@floating-ui/react";
import { offset } from "@floating-ui/dom";
import styled from "styled-components";
import { useRouter } from "next/router";

const Root = styled.div`
  position: relative;
`;

const Content = styled.div`
  min-width: 450px;
  padding: 1.5rem 1rem;
  background: ${({ theme }) => theme.color.backgroundLighter};
  border-radius: 5px;
  box-shadow: 0 7px 30px -10px ${({ theme }) => theme.color.black};
  border: 1px solid #383940;
`;

type Props = {
  trigger: React.ReactNode;
  children: React.ReactNode;
};

export const Popup = ({ trigger, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { route } = useRouter();

  useEffect(() => {
    if (isOpen) {
      // close popup when route changes
      setIsOpen(false);
    }
  }, [route]);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(20)],
  });

  const focus = useFocus(context);
  const hover = useHover(context, {
    handleClose: safePolygon({
      requireIntent: false,
    }),
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
  ]);

  const { styles } = useTransitionStyles(context);

  return (
    <Root>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {trigger}
      </div>

      {isOpen && (
        <Content
          ref={refs.setFloating}
          {...getFloatingProps({
            style: { ...styles, ...floatingStyles },
          })}
        >
          {children}
        </Content>
      )}
    </Root>
  );
};
