import React from "react";
import styled from "styled-components";

type Props = {
  value: number;
  label?: React.ReactNode;
  className?: string;
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.black70};
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  height: 25px;
`;

export const Indicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.color.selection};
  transition: linear 0.1s;
`;

export const Label = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  text-shadow: 0 1px 1px #000;
  transition: linear 0.1s;
  font-size: 1.2rem;
`;

export const Progress = ({ className, value, label }: Props) => (
  <Root className={className}>
    <Indicator
      style={{
        right: `${100 - value}%`,
      }}
    />
    <Label>{label ?? `${value}%`}</Label>
  </Root>
);
