import { renderTemplate } from "../../utils";
import { Text } from "../../components";
import { Drawer } from "../../components/Drawer";
import { ToastIcon } from "../../components/Toast";
import Link from "next/link";
import React from "react";
import { Activity as ActivityType, ActivityUI } from "./types";
import styled from "styled-components";

const GroupItem = styled(Drawer.GroupItem)`
  display: grid;
  grid-template-columns: 20px 1fr 80px;
  gap: 1.5rem;
  align-items: center;
  padding: 2rem;
`;

const LogEntry = styled(Text)`
  word-break: break-word;
`;

const safeRenderTemplate = (template: string, context: any) => {
  try {
    return renderTemplate(template, context);
  } catch (e) {
    return null;
  }
};

export const Activity = ({
  activity,
}: {
  activity: ActivityType & ActivityUI;
}) => {
  const href = safeRenderTemplate(activity.url, activity.context);
  const text = safeRenderTemplate(activity.text, activity.context);

  if (!href || !text) {
    return null;
  }

  return (
    <Link href={href} key={activity.id} passHref>
      <GroupItem withLink>
        <ToastIcon type={activity.badge} size="18px" />

        <LogEntry>{text}</LogEntry>

        <Text size="xs" color="gray1">
          {new Date(activity.created_at).toLocaleTimeString()}
        </Text>
      </GroupItem>
    </Link>
  );
};
