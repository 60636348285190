import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { useRouter } from "next/router";
import {
  QuestionCircleFill,
  CreditCardFill,
  CloudsFill,
} from "@styled-icons/bootstrap";
import { Session } from "../server/auth/api";
import { isAdmin } from "./user";

const Root = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ theme }) => theme.navbar.width};
  top: ${({ theme }) => theme.topbar.height};
  bottom: 0;
  background-color: ${({ theme }) => theme.color.backgroundLighter};
  box-shadow: 2px 0 0 0 ${({ theme }) => theme.color.black40};
`;

const Item = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: ${({ theme }) => theme.navbar.width};
  height: 6rem;
  cursor: pointer;

  svg {
    flex: 1;
  }

  a {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    opacity: ${({ active }) => (active ? 1 : 0.6)};
    transition: opacity 100ms ease-in-out;
  }

  a:hover {
    opacity: 1;
    transition: opacity 100ms ease-in-out;
  }
`;

const LinkSpan = styled.span`
  flex: 2;
  font-weight: 400;
`;

const LinkIndicator = styled.div<{ active: boolean }>`
  flex: 0.1;
  content: "";
  width: 3px;
  height: 100%;
  background: ${({ theme, active }) =>
    active ? theme.color.pink : "transparent"};
  opacity: ${({ active }) => (active ? 1 : 0.8)};
`;

type NavbarLink = {
  key: string;
  href: string;
  active: (route: string) => boolean;
  icon: React.ReactNode;
  tooltip: string;
  linkText: string;
  adminFeature?: boolean;
};

const links: NavbarLink[] = [
  {
    key: "clusters",
    href: "/clusters",
    active: (currentRoute: string) =>
      currentRoute === "/clusters" || currentRoute === "/instances",
    icon: <CloudsFill size="18" />,
    tooltip: "Clusters",
    linkText: "Clusters",
  },

  {
    key: "billing",
    /* available only for organisation admins */
    adminFeature: true,
    href: "/billing",
    active: (currentRoute: string) => currentRoute.startsWith("/billing"),
    icon: <CreditCardFill size="18" />,
    tooltip: "Billing",
    linkText: "Billing",
  },

  {
    key: "docs",
    href: "/docs",
    active: (currentRoute: string) => currentRoute.startsWith("/docs"),
    icon: <QuestionCircleFill size="18" />,
    tooltip: "Documentation",
    linkText: "Docs",
  },
];

type Props = {
  session: Session;
};

export const NavBar = ({ session }: Props) => {
  const router = useRouter();

  return (
    <Root>
      {links
        .filter(({ adminFeature }) => (adminFeature ? isAdmin(session) : true))
        .map((link) => (
          <Item active={link.active(router.route)} key={link.key}>
            <Link href={link.href}>
              <a data-hook={`navbar-link-${link.tooltip.toLowerCase()}`}>
                {link.icon}
                <LinkSpan>{link.linkText}</LinkSpan>
                <LinkIndicator active={link.active(router.route)} />
              </a>
            </Link>
          </Item>
        ))}
    </Root>
  );
};
