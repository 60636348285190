import { getPaymentMethods } from "../billing/api";
import { getForms } from "./api";
import { OnboardingStep } from "./types";

export const steps: Omit<OnboardingStep, "status">[] = [
  {
    title: "How experienced are you with TSDBs?",
    subtitle: "Help improve QuestDB by answering a few questions",
    route: "/onboarding",
    completed: async (session) => {
      const forms = await getForms(session);
      return forms.length > 0 && forms[0].status === "completed";
    },
  },

  {
    title: "Create first database",
    subtitle: "Start collecting data in QuestDB",
    route: "/instances/create",
    // all new users get a trial instance, so we just assume this step is completed
    completed: () => Promise.resolve(true),
  },

  // @TODO: implement this onboarding step when import page is available
  /*
   * {
   *   title: "Import data",
   *   subtitle: "Choose from many supported formats or use sample datasets",
   *   route: "/import", // @TODO: route to specific instance, like `/instance/:id/import`
   *   check: () => {
   *     return Promise.resolve(false);
   *   },
   * },
   */

  {
    title: "Add payment method",
    subtitle: "Unlock all QuestDB Cloud features",
    route: "/billing",
    completed: async (session) => (await getPaymentMethods(session)).length > 0,
  },
];
