export enum StoreKey {
  READ_NEWS = "read-news",
  ACTIVITY_LOG_FILTER = "activity-log-filter",
  NEWSLETTER_CONSENT = "newsletter-consent",
}

export const getValue = (key: StoreKey) =>
  typeof window === "undefined" ? "" : localStorage.getItem(key) ?? "";

export const setValue = (key: StoreKey, value: string) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, value);
  }
};

export const removeValue = (key: StoreKey) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};
