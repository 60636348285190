import { useQuery } from "@tanstack/react-query";
import type { NewsItem } from "./types";
import axios from "axios";

export const useNews = () =>
  useQuery(
    ["news"],
    async () => {
      const response = await axios.get<NewsItem[]>("/api/news", {
        params: { category: "cloud,oss" },
      });
      return response.data;
    },
    {
      staleTime: Infinity,
    }
  );
