import React, { ReactNode } from "react";
import styled from "styled-components";
import * as RadixTooltip from "@radix-ui/react-tooltip";

import { Text } from "../Text";
import { ForwardRef } from "../ForwardRef";
import { color } from "../../theme";

type Props = {
  text: ReactNode;
  arrow?: boolean;
  placement?: RadixTooltip.TooltipContentProps["side"];
  sideOffset?: RadixTooltip.TooltipContentProps["sideOffset"];
  children: ReactNode;
  disabled?: boolean;
  className?: string;
};

const Content = styled(RadixTooltip.Content)`
  max-width: 300px;
  padding: 0.7rem 1rem;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.tooltipBackground};
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
`;

export const Tooltip = ({
  text,
  arrow = true,
  children,
  placement = "top",
  disabled = false,
  sideOffset = 5,
}: Props) =>
  disabled ? (
    <>{children}</>
  ) : (
    <RadixTooltip.Root delayDuration={0}>
      <RadixTooltip.Trigger asChild>
        <ForwardRef>{children}</ForwardRef>
      </RadixTooltip.Trigger>

      <Content side={placement} sideOffset={sideOffset}>
        <Text color="foreground">{text}</Text>
        {arrow && <RadixTooltip.Arrow fill={color.tooltipBackground} />}
      </Content>
    </RadixTooltip.Root>
  );

Tooltip.Provider = RadixTooltip.Provider;
