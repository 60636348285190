// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig();

const environments = {
  "cloud.app.questdb.net": "development",
  "cloud.questdb.com": "production",
};

const SENTRY_DSN = process.env.SENTRY_DSN;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    environment: environments[publicRuntimeConfig.baseURL] ?? "test",
    dsn: SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category.startsWith("ui")) {
        const target = hint.event.target;
        const hook = target.dataset.hook;
        breadcrumb.message = hook
          ? `${target.tagName.toLowerCase()}[data-hook="${hook}"]`
          : breadcrumb.message;
      }
      return breadcrumb;
    },
  });
}
