import {
  QueryClient,
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import { Session } from "../../server/auth/api";
import api from "../api";
import { ResponseError } from "../api/types";
import { FormResponse } from "./types";

export const getForms = async (session: Session) =>
  api
    .get<FormResponse[]>({ endpoint: "forms", session })
    // failsafe if forms API is not ready
    .catch(() => []);

export const useForms = (session: Session) =>
  useQuery({
    queryKey: ["forms"],
    queryFn: () => getForms(session),
    staleTime: Infinity,
  });

export const usePatchForm = <Answers extends object>(
  id: string,
  session: Session,
  queryOptions?: UseMutationOptions<FormResponse, ResponseError, Answers>
) =>
  useMutation({
    mutationFn: (form_answers) =>
      api.patch<FormResponse>({
        endpoint: `forms/${id}`,
        data: { form_answers },
        session,
      }),
    ...queryOptions,
  });

export const useCompleteForm = (
  id: string,
  session: Session,
  queryOptions?: UseMutationOptions<FormResponse, ResponseError>
) =>
  useMutation({
    mutationFn: () =>
      api.post<FormResponse>({ endpoint: `forms/${id}/complete`, session }),
    ...queryOptions,
  });

export const useSkipForm = (
  id: string,
  session: Session,
  queryOptions?: UseMutationOptions<FormResponse, ResponseError>
) =>
  useMutation({
    mutationFn: () =>
      api.post<FormResponse>({ endpoint: `forms/${id}/skip`, session }),
    ...queryOptions,
  });

export const prefetchForms = (queryClient: QueryClient, session: Session) =>
  queryClient.prefetchQuery({
    queryKey: ["forms"],
    queryFn: () => getForms(session),
  });
