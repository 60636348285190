import React from "react";
import styled from "styled-components";
import { BoxArrowUpRight as DefaultIcon } from "@styled-icons/bootstrap/BoxArrowUpRight";
import { match, P } from "ts-pattern";
import type { StyledIcon } from "@styled-icons/styled-icon";
import { color } from "../../theme";

type Props = {
  disabled?: boolean;
  onClick?(event: React.MouseEvent): void;
  href?: string;
  title?: string;
  children?: React.ReactNode;
  icon?: StyledIcon | null;
  dataHook?: string;
  className?: string;
  newTab?: boolean;
  noHover?: boolean;
};

const Root = styled.a<{ disabled?: boolean; noHover?: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  color: ${({ theme }) => theme.color.cyan};
  cursor: pointer;
  line-height: 1.5;

  ${({ disabled, theme }) => disabled && `color: ${theme.color.gray1};`}

  ${({ noHover }) =>
    !noHover &&
    `
    &:hover {
      text-decoration: underline;
    }
  `}

  &[disabled]:hover {
    text-decoration: none;
  }
`;

export const Link = ({
  href,
  onClick,
  title,
  children,
  disabled,
  dataHook,
  icon,
  className,
  newTab,
  noHover,
}: Props) => {
  const props = match({ href, onClick, disabled, newTab })
    .with({ disabled: true }, () => ({
      disabled: true,
    }))
    .with({ href: P.string, newTab: false, onClick: P.nullish }, () => ({
      href,
      rel: "noopener noreferrer",
    }))
    .with({ href: P.string, onClick: P.nullish }, () => ({
      href,
      target: "_blank",
      rel: "noopener noreferrer",
    }))
    .with({ href: P.nullish, onClick: P.instanceOf(Function) }, () => ({
      onClick,
    }))
    .with({ href: P.string, onClick: P.instanceOf(Function) }, () => ({
      href,
      onClick,
    }))
    .with({ href: P.nullish, onClick: P.nullish }, () => ({}))
    .exhaustive();

  const iconProps = {
    size: 14,
    color: disabled ? color.gray1 : color.cyan,
  };

  return (
    <Root
      {...props}
      data-hook={dataHook}
      className={className}
      noHover={noHover}
    >
      {children ?? title ?? href}

      {icon ? (
        React.createElement(icon, iconProps)
      ) : icon === null ? null : (
        <DefaultIcon {...iconProps} />
      )}
    </Root>
  );
};
