// @TODO: remove this component and update all usages to use import { FeedbackDialog } from '@questdb/react-components'

import React, { useEffect, useState } from "react";
import { AlertDialog } from "../../components/AlertDialog";
import { ForwardRef } from "../../components/ForwardRef";
import { Button } from "@questdb/react-components";
import { Form } from "../../components/Form";
import { Box } from "../../components/Box";
import { Text } from "../../components/Text";
import Joi from "joi";
import { Overlay } from "../../components/Overlay";
import { Envelope } from "@styled-icons/bootstrap/Envelope";
import { Undo } from "../../components/icons/undo";
import { useFeedback } from "./api";
import { toast } from "../../components/Toast";
import { useRouter } from "next/router";
import type { User } from "../user";
import { Card } from "../../components/Card";
import { X } from "@styled-icons/bootstrap/X";
import { CameraVideo } from "@styled-icons/bootstrap/CameraVideo";
import { useFormContext } from "react-hook-form";
import { Loader } from "../../components/Loader";
import styled from "styled-components";
import { Chat } from "@styled-icons/bootstrap/Chat";
import { Category } from "./types";

type Values = {
  message: string;
};

const minLength = 20;
const maxLength = 1000;
const schema = Joi.object({
  message: Joi.string()
    .min(minLength)
    .max(maxLength)
    .trim()
    .required()
    .messages({
      "string.min": `Please enter at least ${minLength} symbols`,
      "string.max": `Please enter a message shorter than ${maxLength} characters`,
      "string.trim": "Please enter a message",
      "string.empty": "Please enter a message",
    }),
});

const TextArea = styled(Form.TextArea)`
  min-width: 100%;
`;

const Footer = ({ onConfirm }: { onConfirm?: () => void }) => {
  const formContext = useFormContext();
  const { isSubmitting } = formContext.formState;
  const { message } = formContext.watch();

  return (
    <Card.Footer>
      <AlertDialog.Cancel asChild>
        <Button
          skin="transparent"
          prefixIcon={<Undo size={18} />}
          onClick={onConfirm}
        >
          Dismiss
        </Button>
      </AlertDialog.Cancel>

      <AlertDialog.Action asChild>
        <ForwardRef>
          <Form.Submit
            variant="success"
            prefixIcon={
              isSubmitting ? <Loader size={18} /> : <Envelope size={18} />
            }
            disabled={isSubmitting || message.length === 0}
          >
            {isSubmitting ? "Sending..." : "Send"}
          </Form.Submit>
        </ForwardRef>
      </AlertDialog.Action>
    </Card.Footer>
  );
};

type Props = {
  user: User;
  trigger: ({
    setOpen,
  }: {
    setOpen: (open: boolean) => void;
  }) => React.ReactNode;
  title?: string;
  subtitle?: string;
  category: Category;
  initialMessage?: string;
};

export const FeedbackDialog = ({
  user,
  trigger,
  title,
  subtitle,
  category,
  initialMessage,
}: Props) => {
  const [message, setMessage] = useState<string>(initialMessage ?? "");
  const [open, setOpen] = useState(false);
  const feedbackMutation = useFeedback();
  const router = useRouter();

  useEffect(() => {
    setMessage(initialMessage ?? "");
  }, [initialMessage]);

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Trigger asChild>
        <ForwardRef>{trigger({ setOpen })}</ForwardRef>
      </AlertDialog.Trigger>

      <AlertDialog.Portal>
        <ForwardRef>
          <Overlay primitive={AlertDialog.Overlay} />
        </ForwardRef>

        <AlertDialog.Content>
          <Form<Values>
            name="feedback-dialog"
            onSubmit={({ message }: Values) =>
              feedbackMutation.mutate(
                {
                  message,
                  pathname: window.location.origin + router.asPath,
                  email: user.email,
                  category,
                },
                {
                  onSuccess: () => {
                    toast.success("Feedback sent. Thank you!");
                    setMessage(initialMessage ?? "");
                    setOpen(false);
                  },
                  onError: () => {
                    toast.error(
                      "Sorry! Unable to send feedback :( Please try again later."
                    );
                  },
                }
              )
            }
            onChange={({ message }) => {
              setMessage(message ?? "");
            }}
            defaultValues={{ message }}
            validationSchema={schema}
          >
            <Card>
              <Card.Header
                title={title ?? "Get In Touch"}
                subtitle={
                  subtitle ?? "Drop a message and we will come back to you soon"
                }
                beforeTitle={<Chat size={24} />}
                afterTitle={
                  <Button
                    type="button"
                    skin="transparent"
                    onClick={() => setOpen(false)}
                  >
                    <X size={18} />
                  </Button>
                }
              />

              <Card.Content>
                <Form.Item name="message" label="Message">
                  <TextArea name="message" autoFocus />
                  <Box
                    justifyContent="flex-end"
                    style={{
                      color:
                        message.length < minLength || message.length > maxLength
                          ? "rgb(152, 79, 79)"
                          : "#33874b",
                    }}
                  >
                    {message.length}/{maxLength}
                  </Box>
                </Form.Item>

                <Box gap="1rem">
                  <CameraVideo size={24} />

                  <Text>
                    Need more help?{" "}
                    <a
                      href="https://questdb.io/cloud/book-a-demo/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Book a demo
                    </a>
                  </Text>
                </Box>
              </Card.Content>

              <Footer onConfirm={() => setOpen(false)} />
            </Card>
          </Form>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};
