import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Input as UnstyledInput } from "../../Input";
import styled from "styled-components";

interface FormInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  autoFocus?: boolean;
}

const Root = styled.div`
  display: flex;
  width: 100%;
`;

const Input = styled(UnstyledInput)<FormInputProps>`
  ${(props) => props.disabled && `opacity: 0.7;`}
`;

export const FormInput = ({
  name,
  type = "text",
  autoFocus,
  autoComplete,
  ...rest
}: FormInputProps) => {
  const { register, setFocus } = useFormContext();

  useEffect(() => {
    if (autoFocus) {
      setFocus(name);
    }
  }, []);

  return (
    <Root>
      <Input
        {...register(name, {
          valueAsNumber: type === "number",
        })}
        name={name}
        type={type}
        {...rest}
      />
    </Root>
  );
};
